import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../../config/config';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PlantillaService {

  constructor( private http: HttpClient ) { }

  getTurno(token: string) {
    return this.http.post(URL_SERVICIOS + '/vacante/turno?token=' + token,
    {
      token
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  crearServicio( form: FormGroup, token: string) {
    const createFact = {
      form

    };
    return this.http.post(URL_SERVICIOS + '/vacante/insertServicio?token=' + token, form)
    .pipe(
      map( (resp: any) => {
        return resp;
      })
    );
  }

  crearUniformeGuardia( form: FormGroup, token: string) {
    const createFact = {
      form

    };
    return this.http.post(URL_SERVICIOS + '/vacante/insertUniforme?token=' + token, form)
    .pipe(
      map( (resp: any) => {
        return resp;
      })
    );
  }
  getAsignacionUniforme(token: string, id: string) {
    return this.http.post(URL_SERVICIOS + '/vacante/asignacionUniforme?token=' + token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getPapeletaID(token: string, papeleta: string) {
    return this.http.post(URL_SERVICIOS + '/vacante/papeletaUniforme?token=' + token,
    {
      papeleta
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getAsignacionesUniformes(token: string) {
    return this.http.get(URL_SERVICIOS + '/vacante/asignacionesUniformes?token=' + token)
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getPlantilla(token: string, id: string) {
    return this.http.post(URL_SERVICIOS + '/vacante/plantilla?token=' + token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  borrarVacante(id: string, token: string) {
    return this.http.post(URL_SERVICIOS + '/servicio/vacante/delete?token=' + token,
    {
        id,
        token,
        cveEmp : localStorage.getItem('cveEmp')
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      })
    );
  }

  borrarAsignacionUniforme(id: string, token: string) {
    return this.http.post(URL_SERVICIOS + '/vacante/asignacionUniforme/delete?token=' + token,
    {
        id,
        token,
        cveEmp : localStorage.getItem('cveEmp')
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      })
    );
  }

  getPlantillaEstadistico(token: string, id: string) {
    return this.http.post(URL_SERVICIOS + '/vacante/plantillaEstadistico?token=' + token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

}

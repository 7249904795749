export class EmpleadoModel {
    claveEmpleado: string;
    nombre: string;
    apellido: string;
    urlphoto: string;
    claveEmpresa: string;
    correoElectronico: string;
    rfc: string;
    fechaDeNacimiento: string;
    lugarDeNacimiento: string;
    curp: string;
    nns: string;
    claveNivelDeEstudios: string;
    claveTipoDepartamento: string;
    claveTipoStatusEmpleado: string;
    claveRegistroLaboral: string;
    numeroDeEmpleado: string;
    puesto: string;
    servicio: string;
    status: string;
    zona: string;
    tipoVacante: string;
    turno: string;
    claveTurno: string;
    telefono: string;
    claveDeElector: string;
    licenciaDeConducir: string;
    cartillaMilitar: string;

    numero: string;
    calle: string;
    colonia: string;
    entreQueCalles: string;
    cp: string;
    delegacionMunicipio: string;
    estado: string;

    nombreEmerge: string;
    parentescoEmerge: string;
    telefonoEmerge: string;
    alergiasEmerge: string;
    tpSanguinioEmerge: string;

    nombreBeneficiario: string;
    parentescoBeneficiario: string;
    telefonoBeneficiario: string;

    ultimoEmpleo: string;
    antiguedadUltimoEmpleo: string;
    puestoUltimoEmpleo: string;
    motivoSeparacionUltimoEmpleo: string;

    numEmpleado: string;
    fechaIngreso: string;
    puestoEmpleo: string;




    buscaPor: string;
    termino: string;

    constructor() {
        this.buscaPor = 'nombre';
    }
}

<section id="wrapper" class="login-register login-sidebar" style="background-image:url(assets/images/background/login.jpg);">
    <div class="login-box card">
        <div class="card-body">
            <form ngNativeValidate #f="ngForm" class="form-horizontal form-material" (ngSubmit)="ingresar(f)">
                <a href="javascript:void(0)" class="text-center db">PEOPLE TALENT<br/><img src="assets/images/logo-text.png" alt="Home" /></a>
                <div class="form-group m-t-40">
                    <div class="col-xs-12">
                        <input [ngModel]="email" name="email" class="form-control" type="email" required placeholder="Correo del usuario">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input ngModel name="password" class="form-control" type="password" required placeholder="Contraseña">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary pull-left p-t-0">
                            <input [ngModel]="recuerdame" name="recuerdame" id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Recordarme </label>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Ingresar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2';
import { VacanteModel } from '../../models/vacante.model';

@Component({
  selector: 'app-vacantes',
  templateUrl: './vacantes.component.html',
  styleUrls: ['./vacantes.component.css']
})
export class VacantesComponent implements OnInit {
  vacante: VacanteModel[] = [];
  cargando = false;
  result = false;
  token: string;
  config: any;
  constructor(public clienteService: ClientesService) {

    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: clienteService.totalClientes
    };
   }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarVacantes();
  }

  cargarVacantes() {
    this.clienteService.getVacantes(this.token, localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {
      if (resp.response['0'].claveVacante != null) {
         this.vacante = resp.response;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  borrarVacante( vacante: string, i: number) {
    Swal.fire({
      title: '¿Está Seguro?',
      text: `Esta Seguro que desea borrar a ${ vacante}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {
      if (resp.value) {
        this.clienteService.borrarVacante(vacante, this.token)
        .subscribe( () => {
          this.vacante.splice( i, 1 );
          this.cargarVacantes();
        });
      }
    });
  }

  buscarVacante(termino: string ) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null ) {
      this.cargando = false;
      this.cargarVacantes();
      return;
    }
    this.clienteService.getVacanteCliente(this.token, termino, localStorage.getItem('cveEmp'), 'cliente' )
    .subscribe( (resultado: any) => {

      if ( resultado.response['0'] === null) {
        this.cargando = false;
        this.result = true;
        this.vacante = [];
        return;
      } else {
        if ( resultado.response['0'] != null) {
        this.vacante = resultado.response;
        this.cargando = false;
        this.result = false;
        } else {
          this.cargando = false;
          this.result = true;
          this.vacante = [];
          return;

        }
       }
      return  (this.vacante);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { VacanteModel } from '../../../models/vacante.model';
import { PlantillaService } from '../../../services/plantilla/plantilla.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

import { EmpleadoModel } from '../../../models/empleado.model';
import { ContratacionService } from '../../../services/contratacion/contratacion.service';
@Component({
  selector: 'app-uniforme',
  templateUrl: './uniforme.component.html',
  styleUrls: ['./uniforme.component.css']
})
export class UniformeComponent implements OnInit {

  turno: VacanteModel[] = [];
  token: string;
  public form: FormGroup;
  public contactPantalon: FormArray;
  public contactCamisa: FormArray;
  public contactChamarra: FormArray;
  public contactGorra: FormArray;
  public contactChaleco: FormArray;
  public contactZapato: FormArray;
  public contactCorbata: FormArray;
  public contactLente: FormArray;
  public contactCubrebocas: FormArray;
  public contactCareta: FormArray;
  id: any;
  empleado: EmpleadoModel = new EmpleadoModel();


  constructor(private fb: FormBuilder, private plantillaService: PlantillaService, private route: ActivatedRoute, private router: Router,
              public contratacionService: ContratacionService) { }

  // returns all form groups under contacts
  get pantalonFormGroup() {
    return this.form.get('pantalon') as FormArray;
  }
  get camisaFormGroup() {
    return this.form.get('camisa') as FormArray;
  }
  get chamarraFormGroup() {
    return this.form.get('chamarra') as FormArray;
  }
  get gorraFormGroup() {
    return this.form.get('gorra') as FormArray;
  }
  get zapatoFormGroup() {
    return this.form.get('zapato') as FormArray;
  }
  get chalecoFormGroup() {
    return this.form.get('chaleco') as FormArray;
  }
  get corbataFormGroup() {
    return this.form.get('corbata') as FormArray;
  }
  get lenteFormGroup() {
    return this.form.get('lente') as FormArray;
  }
  get cubrebocasFormGroup() {
    return this.form.get('cubrebocas') as FormArray;
  }

  get caretaFormGroup() {
    return this.form.get('careta') as FormArray;
  }


  cargarGuardia(id) {
    this.contratacionService.getEmpleadoID(this.token, id)
      .subscribe((resp: any) => {
        if (resp.response['0'] != null) {
          this.empleado = resp.response['0'];
        }
      });
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.token = localStorage.getItem('token');
    this.cargarGuardia(this.id);

    this.form = this.fb.group({
      empleado: [this.id, Validators.compose([Validators.required])],
      folioPapeleta: ['', Validators.compose([Validators.required])],
      pantalon: this.fb.array([this.crearPantalon()]),
      camisa: this.fb.array([this.crearCamisa()]),
      chamarra: this.fb.array([this.crearChammarra()]),
      gorra: this.fb.array([this.crearGorra()]),
      zapato: this.fb.array([this.crearZapato()]),
      chaleco: this.fb.array([this.crearChaleco()]),
      corbata: this.fb.array([this.crearCorbata()]),
      lente: this.fb.array([this.crearLente()]),
      cubrebocas: this.fb.array([this.crearCubrebocas()]),
      careta: this.fb.array([this.crearCareta()])
    });

    // set contactPantalon to this field
    this.contactPantalon = this.form.get('pantalon') as FormArray;
    this.contactCamisa = this.form.get('camisa') as FormArray;
    this.contactChamarra = this.form.get('chamarra') as FormArray;
    this.contactGorra = this.form.get('gorra') as FormArray;
    this.contactChaleco = this.form.get('chaleco') as FormArray;
    this.contactZapato = this.form.get('zapato') as FormArray;
    this.contactCorbata = this.form.get('corbata') as FormArray;
    this.contactLente = this.form.get('lente') as FormArray;
    this.contactCubrebocas = this.form.get('cubrebocas') as FormArray;
    this.contactCareta = this.form.get('careta') as FormArray;

    this.quitarPersona(0, 'pantalon');
    this.quitarPersona(0, 'camisa');
    this.quitarPersona(0, 'chamarra');
    this.quitarPersona(0, 'gorra');
    this.quitarPersona(0, 'chaleco');
    this.quitarPersona(0, 'zapato');
    this.quitarPersona(0, 'corbata');
    this.quitarPersona(0, 'lente');
    this.quitarPersona(0, 'cubrebocas');
    this.quitarPersona(0, 'careta');
  }


  // contact formgroup
  crearPantalon(): FormGroup {
    return this.fb.group({
      idPantalon: ['1', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadPantalon: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionPantalon: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaPantalon: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoPantalon: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaPantalon: ['', Validators.compose([Validators.required])],
    });
  }

  crearCamisa(): FormGroup {
    return this.fb.group({
      idCamisa: ['2', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadCamisa: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionCamisa: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaCamisa: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoCamisa: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaCamisa: ['', Validators.compose([Validators.required])],
      colorCamisa: ['', Validators.compose([Validators.required])],

    });
  }

  crearChammarra(): FormGroup {
    return this.fb.group({
      idChamarra: ['3', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadChamarra: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionChamarra: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaChamarra: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoChamarra: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaChamarra: ['', Validators.compose([Validators.required])],

    });
  }

  crearGorra(): FormGroup {
    return this.fb.group({
      idGorra: ['4', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadGorra: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionGorra: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaGorra: ['unitalla', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoGorra: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaGorra: ['', Validators.compose([Validators.required])],

    });
  }

  crearChaleco(): FormGroup {
    return this.fb.group({
      idChaleco: ['5', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadChaleco: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionChaleco: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaChaleco: ['unitalla', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoChaleco: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaChaleco: ['', Validators.compose([Validators.required])],
      colorChaleco: ['', Validators.compose([Validators.required])],

    });
  }

  crearZapato(): FormGroup {
    return this.fb.group({
      idZapato: ['6', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadZapato: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionZapato: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaZapato: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoZapato: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaZapato: ['', Validators.compose([Validators.required])],

    });
  }

  crearCorbata(): FormGroup {
    return this.fb.group({
      idCorbata: ['7', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadCorbata: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionCorbata: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaCorbata: ['unitalla', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoCorbata: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaCorbata: ['', Validators.compose([Validators.required])],

    });
  }

  crearLente(): FormGroup {
    return this.fb.group({
      idLente: ['8', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadLente: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionLente: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaLente: ['unitalla', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoLente: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaLente: ['', Validators.compose([Validators.required])],
    });
  }

  crearCubrebocas(): FormGroup {
    return this.fb.group({
      idCubrebocas: ['9', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadCubrebocas: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionCubrebocas: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaCubrebocas: ['unitalla', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoCubrebocas: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaCubrebocas: ['', Validators.compose([Validators.required])],
      colorCubrebocas: ['', Validators.compose([Validators.required])],
    });
  }

  crearCareta(): FormGroup {
    return this.fb.group({
      idCareta: ['10', Validators.compose([Validators.required])], // i.e Email, Phone
      cantidadCareta: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      descripcionCareta: ['na', Validators.compose([Validators.required])], // i.e. Home, Office
      tallaCareta: ['unitalla', Validators.compose([Validators.required])], // i.e. Home, Office
      estadoCareta: ['N', Validators.compose([Validators.required])], // i.e. Home, Office
      fechaEntregaCareta: ['', Validators.compose([Validators.required])],
    });
  }



  // add a contact form group
  AgregarPersona(tipoUniforme: string) {
    if (tipoUniforme === 'pantalon') {
      this.contactPantalon.push(this.crearPantalon());
    } else {
      if (tipoUniforme === 'camisa') {
        this.contactCamisa.push(this.crearCamisa());

      } else {
        if (tipoUniforme === 'chamarra') {
          this.contactChamarra.push(this.crearChammarra());

        } else {
          if (tipoUniforme === 'gorra') {
            this.contactGorra.push(this.crearGorra());
          } else {
            if (tipoUniforme === 'zapato') {
              this.contactZapato.push(this.crearZapato());
            } else {
              if (tipoUniforme === 'chaleco') {
                this.contactChaleco.push(this.crearChaleco());
              } else {
                if (tipoUniforme === 'corbata') {
                  this.contactCorbata.push(this.crearCorbata());
                } else {
                  if (tipoUniforme === 'lente') {
                    this.contactLente.push(this.crearLente());
                  } else {
                    if (tipoUniforme === 'cubrebocas') {
                      this.contactCubrebocas.push(this.crearCubrebocas());
                    } else {
                      if (tipoUniforme === 'careta') {
                        this.contactCareta.push(this.crearCareta());
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  // remove contact from group
  quitarPersona(index, tipoUniforme: string) {
    if (tipoUniforme === 'pantalon') {
      this.contactPantalon.removeAt(index);
    } else {
      if (tipoUniforme === 'camisa') {
        this.contactCamisa.removeAt(index);

      } else {
        if (tipoUniforme === 'chamarra') {
          this.contactChamarra.removeAt(index);

        } else {
          if (tipoUniforme === 'gorra') {
            this.contactGorra.removeAt(index);

          } else {
            if (tipoUniforme === 'zapato') {
              this.contactZapato.removeAt(index);

            } else {
              if (tipoUniforme === 'chaleco') {
                this.contactChaleco.removeAt(index);

              } else {
                if (tipoUniforme === 'corbata') {
                  this.contactCorbata.removeAt(index);
                } else {
                  if (tipoUniforme === 'lente') {
                    this.contactLente.removeAt(index);
                  } else {
                    if (tipoUniforme === 'cubrebocas') {
                      this.contactCubrebocas.removeAt(index);
                    }else {
                      if (tipoUniforme === 'careta') {
                        this.contactCareta.removeAt(index);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // get the formgroup under contacts form array
  getPantalonFormGroup(index): FormGroup {
    const formGroup = this.contactPantalon.controls[index] as FormGroup;
    return formGroup;
  }

  getCamisaFormGroup(index): FormGroup {
    const formGroup = this.contactCamisa.controls[index] as FormGroup;
    return formGroup;
  }

  getChamarraFormGroup(index): FormGroup {
    const formGroup = this.contactChamarra.controls[index] as FormGroup;
    return formGroup;
  }

  getGorraFormGroup(index): FormGroup {
    const formGroup = this.contactGorra.controls[index] as FormGroup;
    return formGroup;
  }

  getZapatoFormGroup(index): FormGroup {
    const formGroup = this.contactZapato.controls[index] as FormGroup;
    return formGroup;
  }

  getChalecoFormGroup(index): FormGroup {
    const formGroup = this.contactChaleco.controls[index] as FormGroup;
    return formGroup;
  }

  getCorbataFormGroup(index): FormGroup {
    const formGroup = this.contactCorbata.controls[index] as FormGroup;
    return formGroup;
  }

  getLenteFormGroup(index): FormGroup {
    const formGroup = this.contactLente.controls[index] as FormGroup;
    return formGroup;
  }

  getCubrebocasFormGroup(index): FormGroup {
    const formGroup = this.contactCubrebocas.controls[index] as FormGroup;
    return formGroup;
  }

  getCaretaFormGroup(index): FormGroup {
    const formGroup = this.contactCareta.controls[index] as FormGroup;
    return formGroup;
  }
  // method triggered when form is submitted
  submit() {
    if (!this.form.valid) {
      Swal.fire({
        title: 'Falta info por llenar',
        text: 'completa la información',
        type: 'warning'
      });
      return;
    } else {
      console.log(this.form.value);
      this.guardar(this.form.value);
    }
  }

  guardar(form: FormGroup) {

    if (form.invalid) {
      Swal.fire({
        title: 'Falta info por llena',
        text: 'completa la información',
        type: 'warning'
      });
      return;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;

    peticion = this.plantillaService.crearUniformeGuardia(form, this.token);

    peticion.subscribe(resp => {
      if (resp.response === 'ok') {
        Swal.fire({
          title: 'correcto',
          text: 'Se Registro correctamente',
          type: 'success'
        });
        this.form.reset();
        this.router.navigate(['/asignacionUniforme', this.id]);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Intentelo de nuevo',
          type: 'warning'
        });
      }
    });
  }
}

<div class="col-12">
    <form [formGroup]="form" (submit)="submit()" #myform="ngForm" (keydown.enter)="$event.preventDefault()">
        <div class="card">

            <div class="card-header">Registro de Uniforme a entregar</div>
            <div class="card-body border border-secondary">
                <div class="text-right">
                    <button [routerLink]="['/contrataciones']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger">
                                               <i class="fa fa-arrow-left"> Regresar</i>
                    </button>

                </div>
                <div class="row">
                    <div class="col-4" align="center">
                        <img [src]='empleado.urlphoto' width='200px' height='230px' />
                    </div>
                    <div class="form-group col-8">
                        <label><strong>Nombre: </strong> {{empleado.nombre | titlecase}} {{empleado.apellido | titlecase }}</label>
                        <br>
                        <label><strong>Puesto: </strong> {{empleado.puesto | titlecase}}</label>
                        <br>
                        <label><strong>Fecha de ingreso: </strong> {{empleado.fechaIngreso | titlecase}}</label>

                    </div>
                </div>

            </div>

            <div class="card-body border border-secondary">
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('pantalon')"><i class="fa fa-plus-circle"></i><strong> pantalón (PT) </strong><span class="badge badge-light"><strong>{{pantalonFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('camisa')"><i class="fa fa-plus-circle"></i><strong> camisa (CM) </strong><span class="badge badge-light"><strong>{{camisaFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('chamarra')"><i class="fa fa-plus-circle"></i><strong> chamarra (CH) </strong><span class="badge badge-light"><strong>{{chamarraFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('gorra')"><i class="fa fa-plus-circle"></i><strong> gorra (G) </strong><span class="badge badge-light"><strong>{{gorraFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('chaleco')"><i class="fa fa-plus-circle"></i><strong> chaleco (CHL) </strong><span class="badge badge-light"><strong>{{chalecoFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('zapato')"><i class="fa fa-plus-circle"></i><strong> Zapatos (Z) </strong><span class="badge badge-light"><strong>{{zapatoFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('corbata')"><i class="fa fa-plus-circle"></i><strong> Corbata (C) </strong><span class="badge badge-light"><strong>{{corbataFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('lente')"><i class="fa fa-plus-circle"></i><strong> Lente (L) </strong><span class="badge badge-light"><strong>{{lenteFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('cubrebocas')"><i class="fa fa-plus-circle"></i><strong> Cubrebocas (CR) </strong><span class="badge badge-light"><strong>{{cubrebocasFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary  col-md-3" type="button" (click)="AgregarPersona('careta')"><i class="fa fa-plus-circle"></i><strong> Careta (CA) </strong><span class="badge badge-light"><strong>{{caretaFormGroup.controls.length}}</strong></span></button>
            </div>

            <div class="card-body border border-secondary">
                <div class="card-header">
                    <h4><strong>Registro de papeleta</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="form-group col-md-12 row animated fadeIn">
                        <label class="col-sm-3 control-label m-b-5">folio de papeleta</label>
                        <div class="col-sm-9 m-b-5">
                            <div class="input-group">
                                <div class="input-group-addon"><span>#</span></div>
                                <input class="form-control" type="text" formControlName="folioPapeleta">
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="pantalonFormGroup.length" class="card-body border border-secondary" formArrayName="pantalon">
                <div class="card-header">
                    <h4><strong>Registro de pantalones</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of pantalonFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de pantalon # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaPantalon" type="text">
                                            <option [value]="28">28</option>
                                            <option [value]="30">30</option>
                                            <option [value]="32">32</option>
                                            <option [value]="34">34</option>
                                            <option [value]="36">36</option>
                                            <option [value]="38">38</option>
                                            <option [value]="40">40</option>
                                            <option [value]="42">42</option>
                                            <option [value]="44">44</option>
                                            <option [value]="46">46</option>
                                            <option [value]="46">46</option>
                                            <option [value]="48">48</option>
                                            <option [value]="50">50</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['tallaPantalon'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de pantalon # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadPantalon" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['cantidadPantalon'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionPantalon" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['descripcionPantalon'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado del pantalon # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoPantalon" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['estadoPantalon'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaPantalon" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['fechaEntregaPantalon'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'pantalon')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="camisaFormGroup.length" class="card-body border border-secondary" formArrayName="camisa">
                <div class="card-header">
                    <h4><strong>Registro de camisas</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let camisa of camisaFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de camisa # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaCamisa" type="text">
                                            <option [value]="28">28</option>
                                            <option [value]="30">30</option>
                                            <option [value]="32">32</option>
                                            <option [value]="34">34</option>
                                            <option [value]="36">36</option>
                                            <option [value]="38">38</option>
                                            <option [value]="40">40</option>
                                            <option [value]="42">42</option>
                                            <option [value]="44">44</option>
                                            <option [value]="46">46</option>
                                            <option [value]="48">48</option>
                                            <option [value]="50">50</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['tallaCamisa'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de camisa # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadCamisa" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['cantidadCamisa'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionCamisa" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['descripcionCamisa'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de la camisa # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoCamisa" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['estadoCamisa'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaCamisa" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['fechaEntregaCamisa'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>

                                <div class="col-sm-4">
                                    <label class="control-label">color de camisa # {{i + 1}}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="colorCamisa" type="text">
                                            <option value="Sin definir">Sin definir</option>
                                            <option value="Blanco">Blanco</option>
                                            <option value="Gris">Gris</option>
                                            <option value="Morado Cuadros">Morado Cuadros</option>
                                            <option value="Azul blanco rayas">Azul blanco rayas</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['colorCamisa'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>

                                <div class="col-sm-2" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'camisa')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="chamarraFormGroup.length" class="card-body border border-secondary" formArrayName="chamarra">
                <div class="card-header">
                    <h4><strong>Registro de chamarras</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let chamarra of chamarraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de chamarra # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaChamarra" type="text">
                                            <option [value]="28">28</option>
                                            <option [value]="30">30</option>
                                            <option [value]="32">32</option>
                                            <option [value]="34">34</option>
                                            <option [value]="36">36</option>
                                            <option [value]="38">38</option>
                                            <option [value]="40">40</option>
                                            <option [value]="42">42</option>
                                            <option [value]="44">44</option>
                                            <option [value]="46">46</option>
                                            <option [value]="48">48</option>
                                            <option [value]="50">50</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['tallaChamarra'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de chamarra # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadChamarra" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['cantidadChamarra'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionChamarra" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['descripcionChamarra'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de la chamarra # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoChamarra" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['estadoChamarra'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaChamarra" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['fechaEntregaChamarra'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'chamarra')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="gorraFormGroup.length" class="card-body border border-secondary" formArrayName="gorra">
                <div class="card-header">
                    <h4><strong>Registro de Gorras</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let gorra of gorraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de gorra # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaGorra" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['tallaGorra'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de gorras # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadGorra" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['cantidadGorra'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionGorra" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['descripcionGorra'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de la gorra # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoGorra" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['estadoGorra'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaGorra" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['fechaEntregaGorra'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'gorra')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="chalecoFormGroup.length" class="card-body border border-secondary" formArrayName="chaleco">
                <div class="card-header">
                    <h4><strong>Registro de Chalecos</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let chaleco of chalecoFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de chaleco # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaChaleco" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['tallaChaleco'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de chalecos # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadChaleco" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['cantidadChaleco'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionChaleco" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['descripcionChaleco'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado del chaleco # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoChaleco" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['estadoChaleco'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaChaleco" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['fechaEntregaChaleco'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>

                                <div class="col-sm-4">
                                    <label class="control-label">color del chaleco # {{i + 1}}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="colorChaleco" type="text">
                                            <option value="Sin definir">Sin definir</option>
                                            <option value="Naranja">Naranja</option>
                                            <option value="Amarillo">Amarillo</option>
                                            <option value="Azul">Azul</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['colorChaleco'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>


                                <div class="col-sm-2" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'chaleco')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="zapatoFormGroup.length" class="card-body border border-secondary" formArrayName="zapato">
                <div class="card-header">
                    <h4><strong>Registro de Zapatos</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let zapato of zapatoFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de zapato # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaZapato" type="text">
                                            <option [value]="23">23</option>
                                            <option [value]="24">24</option>
                                            <option [value]="25">25</option>
                                            <option [value]="26">26</option>
                                            <option [value]="27">27</option>
                                            <option [value]="28">28</option>
                                            <option [value]="29">29</option>
                                            <option [value]="30">30</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['tallaZapato'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de zapatos # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadZapato" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['cantidadZapato'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>

                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionZapato" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['descripcionZapato'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado del zapato # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoZapato" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['estadoZapato'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaZapato" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['fechaEntregaZapato'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'zapato')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="corbataFormGroup.length" class="card-body border border-secondary" formArrayName="corbata">
                <div class="card-header">
                    <h4><strong>Registro de Corbatas</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let corbata of corbataFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de corbata # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaCorbata" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCorbataFormGroup(i).controls['tallaCorbata'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de corbata # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadCorbata" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCorbataFormGroup(i).controls['cantidadCorbata'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionCorbata" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCorbataFormGroup(i).controls['descripcionCorbata'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de corbata # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoCorbata" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCorbataFormGroup(i).controls['estadoCorbata'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaCorbata" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCorbataFormGroup(i).controls['fechaEntregaCorbata'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'corbata')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="lenteFormGroup.length" class="card-body border border-secondary" formArrayName="lente">
                <div class="card-header">
                    <h4><strong>Registro de Lentes</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let lente of lenteFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de lente # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaLente" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getLenteFormGroup(i).controls['tallaLente'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de lentes # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadLente" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getLenteFormGroup(i).controls['cantidadLente'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionLente" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getLenteFormGroup(i).controls['descripcionLente'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de lente # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoLente" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getLenteFormGroup(i).controls['estadoLente'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaLente" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getLenteFormGroup(i).controls['fechaEntregaLente'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'lente')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="cubrebocasFormGroup.length" class="card-body border border-secondary" formArrayName="cubrebocas">
                <div class="card-header">
                    <h4><strong>Registro de Cubrebocas</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let cubrebocas of cubrebocasFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de cubrebocas # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaCubrebocas" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCubrebocasFormGroup(i).controls['tallaCubrebocas'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de cubrebocas # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadCubrebocas" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCubrebocasFormGroup(i).controls['cantidadCubrebocas'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionCubrebocas" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCubrebocasFormGroup(i).controls['descripcionCubrebocas'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de cubrebocas # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoCubrebocas" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCubrebocasFormGroup(i).controls['estadoCubrebocas'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaCubrebocas" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCubrebocasFormGroup(i).controls['fechaEntregaCubrebocas'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>

                                <div class="col-sm-4">
                                    <label class="control-label">color del cubrebocas # {{i + 1}}</label>
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="colorCubrebocas" type="text">
                                            <option value="Sin definir">Sin definir</option>
                                            <option value="Claros">Claros</option>
                                            <option value="Oscuros">Oscuros</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCubrebocasFormGroup(i).controls['colorCubrebocas'].hasError('required') && myform.submitted">
                                        el color es requerido
                                    </span>
                                </div>

                                <div class="col-sm-2" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'cubrebocas')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="caretaFormGroup.length" class="card-body border border-secondary" formArrayName="careta">
                <div class="card-header">
                    <h4><strong>Registro de Caretas</strong></h4>
                </div>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let careta of caretaFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de careta # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaCareta" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCaretaFormGroup(i).controls['tallaCareta'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de caretas # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">

                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadCareta" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCaretaFormGroup(i).controls['cantidadCareta'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Tipo de entrega # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="descripcionCareta" type="text">
                                                    <option value="Gratis">Gratis</option>
                                                    <option value="Descuento">Descuento</option>
                                                    <option value="Cambio por cambio">Cambio por cambio</option>
                                            </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCaretaFormGroup(i).controls['descripcionCareta'].hasError('required') && myform.submitted">
                                            tipo de entrega es requerido
                                    </span>
                                </div>
                                <label class="col-sm-3 control-label">Estado de careta # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoCareta" type="text">
                                                <option value="N">Nuevo (N)</option>
                                                <option value="U">USADO (U)</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCaretaFormGroup(i).controls['estadoCareta'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaCareta" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCaretaFormGroup(i).controls['fechaEntregaCareta'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'careta')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body" *ngIf="pantalonFormGroup.length || camisaFormGroup.length || chamarraFormGroup.length  || gorraFormGroup.length || 
                                          zapatoFormGroup.length || chalecoFormGroup.length || cubrebocasFormGroup.length || lenteFormGroup.length ||
                                          caretaFormGroup.length || corbataFormGroup.length
                                          ">
                <div class="col-12">
                    <button class="btn btn-success mr-1" type="submit"> <i class="fa fa-check"></i>  Guardar Plantilla </button>
                    <button [routerLink]="['/clientes']" type="button" class="btn btn-danger">Cancelar</button>

                </div>
            </div>
        </div>
    </form>
</div>
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { ProductoModel } from '../../models/producto.model';
import { ProductoService } from '../../services/producto/producto.service';
import { EmpleadoCorporativoModel } from '../../models/empleado-corporativo';

@Component({
  selector: 'app-asignacion-equipo',
  templateUrl: './asignacion-equipo.component.html',
  styleUrls: ['./asignacion-equipo.component.css']
})
export class AsignacionEquipoComponent implements OnInit {

  producto: ProductoModel[] = [];
  empleado: EmpleadoCorporativoModel[] = [];
  token: string;
  equipoID: string;
  empleadoID: string;

  constructor(private productoService: ProductoService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.cargarProductoDisponible();
    this.cargarEmpleadoCorporativo();
  }


  guardar(form: NgForm) {

    if (form.invalid) {
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    peticion = this.productoService.insertProductoAsignado(this.empleadoID, this.equipoID, this.token);
    
    peticion.subscribe( resp => {
      Swal.fire({
        title: 'Registro',
        text: 'Se Registro correctamente',
        type: 'success'
      });
    });
  }

  cargarProductoDisponible() {
    this.productoService.getProductoDisponible(this.token, localStorage.getItem('cveEmp'))
    .subscribe( (resp: any) => {
      if (resp['0'].claveProducto != null) {
         this.producto = resp;
      } 
    });
  }

  cargarEmpleadoCorporativo() {
    this.productoService.getEmpleadoCorporativo(this.token, localStorage.getItem('cveEmp'))
    .subscribe( (resp: any) => {

      if (resp['0'].claveEmpleado != null) {
         this.empleado = resp;
      }
       
    });
  }

}

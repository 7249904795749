export class  ProductoModel {


    constructor(public claveProducto: number,
                public identificador: string,
                public modelo: string,
                public procesador: string,
                public ram: string,
                public discoDuro: string,
                public sistemaOperativo: string,
                public marca: string,
                public claveTipoProducto: string,
                public tipoProducto: string,
                public observacion: string,
                public status: string,
                public urlphoto: string,
                public nombre: string,
                public apellido: string,
                public tipoDepartamento: string,
                public puesto: string) {
    }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-grafico-dona',
  templateUrl: './grafico-dona.component.html',
  styles: []
})
export class GraficoDonaComponent implements OnInit {
// tslint:disable-next-line: no-input-rename
  @Input('chartLabels') doughnutChartLabels: string[] = [];
  // tslint:disable-next-line: no-input-rename
  @Input('chartData') doughnutChartData: number[] = [];
  // tslint:disable-next-line: no-input-rename
  @Input('chartType') doughnutChartType = '';
  // tslint:disable-next-line: no-input-rename
  // @Input('chartColor') doughnutColors: string[] = [];

  public chartColors: Array<any> = [

    {

      backgroundColor: ['gray', '#5cb85c', 'red', '#FFCC00', '#4AB190', '#344E5C', '#E17A47', '#533637', '#DDC1C6'],

      hoverBackgroundColor: ['gray', '#5cb85c', 'red', '#FFCC00', '#4AB190', '#344E5C', '#E17A47', '#533637', '#DDC1C6'],

      borderWidth: 2,

    }

  ];

  constructor() { }

  ngOnInit() {
  }

}

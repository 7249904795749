<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">
    <app-header></app-header>
    <app-sidebar></app-sidebar>

    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <app-breadcrumbs></app-breadcrumbs>
            <router-outlet></router-outlet>
            <!--router-outlet-->

            <app-footer></app-footer>
        </div>
        <!-- ============================================================== -->
        <!--END Container fluid  -->
        <!-- ============================================================== -->
    </div>
    <!-- END Page wrapper  -->
    <!-- ============================================================== -->
</div>
import { Component, OnInit } from '@angular/core';
import { ClienteServicioModel } from 'src/app/models/clienteServicio.model';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2';
import { ServicioModel } from '../../models/servicio.model';

@Component({
  selector: 'app-servicio-cliente',
  templateUrl: './servicio-cliente.component.html',
  styleUrls: ['./servicio-cliente.component.css']
})
export class ServicioClienteComponent implements OnInit {
  clienteServicio: ClienteServicioModel[] = [];
  servicio: ServicioModel[] = [];
  cargando = false;
  result = false;
  token: string;
  nombreEmpresa: string;
  config: any;
  sinCubrir = 0;
  cubierto = 0;
  accionUpdate = false;
  constructor(public clienteService: ClientesService) {

    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: clienteService.totalClientes
    };

   }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.token = localStorage.getItem('token');
    this. cargarServicioCantidad();
    this.cargarClientes();
  }

  cargarClientes() {
    this.clienteService.getClienteServicios(this.token, localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {

      if (resp.response['0'].claveCliente != null) {
         this.clienteServicio = resp.response;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  detalleInfo(id: string, modal: string) {
    this.servicio = null;
    if (modal === 'cubierto') {
    this.accionUpdate = false;
    this.clienteService.getDetalleServicio(this.token, id, localStorage.getItem('cveEmp'))
    .subscribe( (resp: any) => {
      if (resp.response['0'].nombreEmpresa != null) {
        this.servicio = resp.response;
        this.nombreEmpresa = resp.response['0'].nombreEmpresa;
      }
    });
   } else {
    this.accionUpdate = true;
   }
  }

  buscarServicioCliente(termino: string ) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null ) {
      this.cargando = false;
      this.cargarClientes();
      return;
    }
    this.clienteService.getClienteServicio(this.token, termino, localStorage.getItem('cveEmp'), 'cliente' )
    .subscribe( (resultado: any) => {

      if ( resultado.response['0'] === null) {
        this.cargando = false;
        this.result = true;
        this.clienteServicio = [];
        return;
      } else {
        if ( resultado.response['0'] != null) {
        this.clienteServicio = resultado.response;
        this.cargando = false;
        this.result = false;
        } else {
          this.cargando = false;
          this.result = true;
          this.clienteServicio = [];
          return;

        }
       }
      return  (this.clienteServicio);
    });
  }

  cargarServicioCantidad() {
    this.clienteService.getClienteServicioCantidad(this.token, 'servicio', 'tipo')
    .subscribe( (resp: any) => {

      if (resp.response['0'] != null) {
         this.sinCubrir = resp.response['0'].SinCubrir;
         this.cubierto = resp.response['0'].Cubiertos;
      }
    });
  }

  download(id: string) {
    console.log(id);
    this.clienteService.download(id, 'servicios')
    .subscribe(res => {
      console.log(res);
      // window.open(window.URL.createObjectURL(res));
      const file = new Blob([res], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
  });
}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ProductoModel } from '../../models/producto.model';
import { URL_SERVICIOS } from '../../../config/config';
import { Estadisticas } from '../../models/estadisticas.interface';
@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor( private http: HttpClient ) { }

  getProducto(token: string, cveEmp: string) {
    return this.http.post(URL_SERVICIOS + '/producto?token=' + token,
    {
      claveEmpresa: cveEmp
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp;
       return response;
      }));
  }

  getProductoDisponible(token: string, cveEmp: string) {
    return this.http.post(URL_SERVICIOS + '/producto/disponible?token=' + token,
    {
      claveEmpresa: cveEmp
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp;
       return response;
      }));
  }

  getEmpleadoCorporativo(token: string, cveEmp: string) {
    return this.http.post(URL_SERVICIOS + '/producto/empleadocorporativo?token=' + token,
    {
      claveEmpresa: cveEmp
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp;
       return response;
      }));
  }

  getProductoAsignado(token: string, cveEmp: string) {
    return this.http.post(URL_SERVICIOS + '/producto/asignado?token=' + token,
    {
      claveEmpresa: cveEmp
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp;
       return response;
      }));
  }

  insertProductoAsignado(empleadoID: string, equipoID: string, token: string) {
    return this.http.post(URL_SERVICIOS + '/producto/asignado/insert?token=' + token,
    {
      claveEmpleado: empleadoID,
      equipo: equipoID
    })
    .pipe(
      map( (resp: any) => {
       return resp;
      }));
  }

  getProductoEstadistico(token: string, cveEmp: string) {
    return this.http.post(URL_SERVICIOS + '/producto/estadistico?token=' + token,
    {
      claveEmpresa: cveEmp
    })
    .pipe(
      map(  (resp: any) => {
        const { response } = resp;
       return response;
      }));
  }

  getProductoID(token: string, id: string) {
    return this.http.post(URL_SERVICIOS + '/producto/productoID?token=' + token,
    {
      id
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp
       return response;
      }));
  }

  getProductoSearch(token: string, termino: string) {
    return this.http.post(URL_SERVICIOS + '/producto/productoSearch?token=' + token,
    {
      termino
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp;
       return response;
      }));
  }

  getProductoSearchIdentificador(token: string, termino: string) {
    return this.http.post(URL_SERVICIOS + '/producto/productoTerminoSearch?token=' + token,
    {
      termino
    })
    .pipe(
      map( (resp: any) => {
        const { response } = resp
       return resp;
      }));
  }

  crearProducto( producto: ProductoModel, token: string) {
    const createFact = {
      identificador: producto.identificador,
      modelo: producto.modelo,
      procesador: producto.procesador,
      ram: producto.ram,
      discoDuro: producto.discoDuro,
      sistemaOperativo: producto.sistemaOperativo,
      marca: producto.marca,
      claveTipoProducto: producto.claveTipoProducto,
      token,
      cveEmp: localStorage.getItem('cveEmp'),
      observacion: producto.observacion,
      status: producto.status

    };
    return this.http.post(URL_SERVICIOS + '/producto/insertProducto?token=' + token, createFact)
    .pipe(
      map( (resp: any) => {
        return producto;
      })
    );
  }

  actualizarProducto( producto: ProductoModel, token: string ) {
    const updateProducto = {
      identificador: producto.identificador,
      modelo: producto.modelo,
      procesador: producto.procesador,
      ram: producto.ram,
      discoDuro: producto.discoDuro,
      sistemaOperativo: producto.sistemaOperativo,
      marca: producto.marca,
      claveTipoProducto: producto.claveTipoProducto,
      token,
      cveEmp: localStorage.getItem('cveEmp'),
      observacion: producto.observacion,
      claveProducto: producto.claveProducto,
      status: producto.status
    };
    return this.http.put(`${ URL_SERVICIOS }/producto/updateProducto?token=` + token, updateProducto);
  }
}

<div class="row">
    <div class="col-lg-4">
        <img src="assets/images/background/finance2.jpg" class="img-landscape" alt="" width="100%" height="100%" />
    </div>
    <div class="col-lg-8">
        <h1> People Talent V1</h1>
        <p class="lead" align="justify">
            quiero darte la bienvenida a la version 1 de people talent el cual esta desarrollado para contratación de personal el cual cuenta con reportes especificos a detalle así como alertas para el control de contrataciones de la empresa.
        </p>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
  cliente: ClienteModel[] = [];
  filtroNombre: any;
  cargando = false;
  result = false;
  config: any;
  checkFactura: string;
  id = 'nuevo';
  baja: number;
  activo: number;
  numeros: any[];
  texto = ['Clientes Registrados', 'Clientes Activos', 'Clientes Bajas'];
  icon = ['fas fa-user', 'fas fa-user', 'fas fa-user'];

  constructor(public clienteService: ClientesService) {
   }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.filtroNombre = '';
    this.numeros = [0, 0, 0];
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };
    this.cargando = true;
    this.cargarClientes();
  }

  cargarClientes() {
    this.clienteService.getClientes(localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {

      if (resp.response['0'].claveCliente != null) {
         this.cliente = resp.response;
         this.numeros = [this.cliente.length, resp.response['0'].activo, resp.response['0'].baja];
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  borrarCliente( cliente: ClienteModel, i: number) {
    Swal.fire({
      title: '¿Está Seguro?',
      text: `Esta Seguro que desea borrar a ${ cliente.claveCliente}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {
      if (resp.value) {
        this.clienteService.borrarCliente(cliente.claveCliente)
        .subscribe( () => {
          this.cliente.splice( i, 1 );
          this.cargarClientes();
        });
      }
    });
  }


  download(id: string) {

    this.clienteService.download(id, 'servicios')
    .subscribe(res => {
      console.log(res);
      const file = new Blob([res], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
  });
}

updateReport(id: string) {
  Swal.fire({
    title: 'Espere',
    text: 'Actualizando Reporte',
    type: 'info',
    allowOutsideClick: false
  });
  Swal.showLoading();

  this.clienteService.updateReport(id)
  .subscribe(res => {
    Swal.fire({
      title: 'PDF actualizado',
      text: 'Actualizado correctamente',
      type: 'success'
    });
});
}
}

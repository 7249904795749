import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  {
   path: 'login',
   component: LoginComponent
  },
  { path: '',
    component: LoginComponent
  },
  { path: '**',
    component: LoginComponent
  }
];
export const AppRoutingModule = RouterModule.forRoot( routes, { useHash: true, relativeLinkResolution: 'legacy' } );

import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from '../pages/pages.component';

// guards
import { AuthGuard } from '../guards/auth.guard';
import { AlmacenGuard } from '../guards/almacen.guard';
import { VerificaTokenGuard } from '../guards/verifica-token.guard';
import { Graficas1Component } from '../pages/graficas1/graficas1.component';
import { AdminGuard } from '../guards/admin.guard';
import { DireccionGuard } from '../guards/direccion.guard';

// Components
import { AlmacenComponent } from './pages/almacen/almacen.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ProductoComponent } from './pages/producto/producto.component';
import { AsignacionesComponent } from './pages/asignaciones/asignaciones.component';
import { UniformeComponent } from './pages/uniforme/uniforme.component';
import { AsignacionUniformeComponent } from './pages/asignacion-uniforme/asignacion-uniforme.component';
import { UniformesComponent } from './pages/uniformes/uniformes.component';
import { EntradaUniformeComponent } from './pages/entrada-uniforme/entrada-uniforme.component';
import { AsignacionesUniformesComponent } from './pages/asignaciones-uniformes/asignaciones-uniformes.component';
import { AsignacionServiciosComponent } from './pages/asignacion-servicios/asignacion-servicios.component';
import { UniformeAsignadoComponent } from './pages/uniforme-asignado/uniforme-asignado.component';
import { AsignacionEquipoComponent } from './pages/asignacion-equipo/asignacion-equipo.component';

// quitar graficas y ponerlo en components generales
const almacenRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [ AuthGuard, VerificaTokenGuard ],
        children: [
             { path: 'graficas1',
               component: Graficas1Component,
               data: { titulo: 'Estadisticas',
               canActivate: [ AuthGuard, VerificaTokenGuard, AdminGuard, DireccionGuard  ]
              }
             },
             { path: 'almacen',
               component: AlmacenComponent,
               data: { titulo: 'Almacen' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
             },
             { path: 'equipos',
             component: ProductosComponent,
             data: { titulo: 'Equipos' },
             canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
           },
           { path: 'producto/:id',
             component: ProductoComponent,
             data: {titulo: 'Producto' },
             canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard ]
           },
           
           { path: 'asignaciones',
             component: AsignacionesComponent,
             data: { titulo: 'Asignación de equipos' },
             canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
           },
           { path: 'uniforme/:id',
               component: UniformeComponent,
               data: {titulo: 'Uniforme' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard ]
           },
           { path: 'asignacionUniforme/:id',
               component: AsignacionUniformeComponent,
               data: {titulo: 'Uniforme' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard ]
           },
           { path: 'uniformes/:id',
               component: UniformesComponent,
               data: {titulo: 'Uniformes' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard ]
           },
           { path: 'entradaUniforme',
               component: EntradaUniformeComponent,
               data: {titulo: 'Registro de Uniformes' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard ]
           },
           { path: 'asignaciones-uniformes',
               component: AsignacionesUniformesComponent,
               data: { titulo: 'Asignaciones de uniformes' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
             },
             { path: 'asignaciones-servicios',
               component: AsignacionServiciosComponent,
               data: { titulo: 'Asignacion a servicios' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
             },
             { path: 'uniforme-asignado',
               component: UniformeAsignadoComponent,
               data: { titulo: 'Uniforme asignado' },
               canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard]
             },
             { path: 'asignacion-equipo/nuevo',
             component: AsignacionEquipoComponent,
             data: {titulo: 'Asignacion Equipo' },
             canActivate: [ AuthGuard, VerificaTokenGuard, AlmacenGuard ]
         },
            ]
    }
];

export const ALMACEN_ROUTES = RouterModule.forChild( almacenRoutes );

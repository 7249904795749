<div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-dark text-center">
                <h4 class="modal-title font-weight-bold text-white text-center" id="myLargeModalLabel"> Detalle </h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-bordered animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-dark">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">#</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Uniforme</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">cantidad</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo entrega</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">folio Papeleta</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">fecha Entrega</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha Registro Sistema</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  *ngFor="let r of reporteUniformeID; let i = index">
                                
                                <td>{{i + 1}}</td>
                                <td>{{ r.tipoUniforme }}</td>
                                <td>{{ r.cantidad }}</td>
                                <td>{{ r.descripcion }}</td>
                                <td>{{ r.folioPapeleta }}</td>
                                <td>{{ r.fechaEntrega | date }}</td>
                                <td>{{ r.fuc | date: 'medium' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-dark waves-effect text-left" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
<form (ngSubmit)="consultar( f )" #f="ngForm" class="card-body">
<div class="card">
    <div class="row col-md-12">
    <div class="col-md-4">
         <div class="form-group">
            <label class="control-label"><strong>Fecha Inicio</strong></label>
            <input type="date" class="form-control" name="fechaInicio"
                 placeholder="Id cliente">
        </div>
    </div>
    <div class="col-md-4">
         <div class="form-group">
            <label class="control-label"><strong>Fecha Fin</strong></label>
            <input type="date" class="form-control" name="fechaFin"
                 placeholder="Id cliente">
        </div>
    </div>
    <div class="col-md-4">
        <div class="mt-4" align="center">
             <button class="btn btn-primary">
               Consultar
             </button>
        </div>
    </div>
</div>
</div>
</form>

<div class="row">
    <div *ngFor="let r of reporteUniforme;" class="col-xl-3 col-md-4 mb-3">
        <div class="card">
            <div class="card-body alert-primary">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-sm font-weight-bold text-dark mb-1">
                            {{r.tipoUniforme}}: </div>
                        <div class="h6 mb-0 font-weight-bold text-gray-800"> {{ r.cantidad }} </div>
                    </div>
                    <div class="col-auto">
                        <button (click)= "cargarReporteID(r.claveTipoUniforme)" class="btn btn-outline-primary btn-sm" data-toggle="modal"
                            data-target=".bs-example-modal-lg">
                            <i class="fas fa-eye fa-2x text-gray-300"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

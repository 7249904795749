import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VacanteModel } from 'src/app/models/vacante.model';
import { PlantillaService } from '../../services/plantilla/plantilla.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plantilla',
  templateUrl: './plantilla.component.html',
  styleUrls: ['./plantilla.component.css']
})
export class PlantillaComponent implements OnInit {

  turno: VacanteModel[] = [];
  token: string;

  public form: FormGroup;
  public contactList: FormArray;
  public contactJS: FormArray;
  public contactJT: FormArray;
  public contactRecepcionista: FormArray;
  public contactCentral: FormArray;
  public contactMonitorista: FormArray;
  public contactRondinero: FormArray;
  public contactGuardiaSeguridad: FormArray;
  id: any;

  constructor(private fb: FormBuilder, private plantillaService: PlantillaService, private route: ActivatedRoute, private router: Router) {}

  // returns all form groups under contacts
  get contactFormGroup() {
    return this.form.get('coordinadores') as FormArray;
  }
  get contactFormGroupJS() {
    return this.form.get('JS') as FormArray;
  }
  get contactFormGroupJT() {
    return this.form.get('JT') as FormArray;
  }
  get contactFormGroupRecepcionista() {
    return this.form.get('recepcionista') as FormArray;
  }
  get contactFormGroupCentral() {
    return this.form.get('central') as FormArray;
  }
  get contactFormGroupMonitorista() {
    return this.form.get('monitorista') as FormArray;
  }
  get contactFormGroupRondinero() {
    return this.form.get('rondinero') as FormArray;
  }
  get contactFormGroupGuardiaSeguridad() {
    return this.form.get('guardiaSeguridad') as FormArray;
  }

  cargarTurnos() {
    this.plantillaService.getTurno(this.token)
    .subscribe( (resp: any) => {
      if (resp.response['0'].claveTurno != null) {
         this.turno = resp.response;
      }
    });
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.token = localStorage.getItem('token');

    this.form = this.fb.group({
      cliente: [this.id, Validators.compose([Validators.required])],
      servicio: [null, Validators.compose([Validators.required])],
      costoServicio: [null, Validators.compose([Validators.required])],
      zonaServicio: [null, Validators.compose([Validators.required])],
      coordinadores: this.fb.array([this.crearCoordinador()]),
      JS: this.fb.array([this.crearJS()]),
      JT: this.fb.array([this.crearJT()]),
      recepcionista: this.fb.array([this.crearRecepcionista()]),
      central: this.fb.array([this.crearCentral()]),
      monitorista: this.fb.array([this.crearMonitorista()]),
      rondinero: this.fb.array([this.crearRondinero()]),
      guardiaSeguridad: this.fb.array([this.crearGuardiaSeguridad()])
    });

    // set contactlist to this field
    this.contactList = this.form.get('coordinadores') as FormArray;
    this.contactJS = this.form.get('JS') as FormArray;
    this.contactJT = this.form.get('JT') as FormArray;
    this.contactRecepcionista = this.form.get('recepcionista') as FormArray;
    this.contactCentral = this.form.get('central') as FormArray;
    this.contactMonitorista = this.form.get('monitorista') as FormArray;
    this.contactRondinero = this.form.get('rondinero') as FormArray;
    this.contactGuardiaSeguridad = this.form.get('guardiaSeguridad') as FormArray;

    this.cargarTurnos();

    this.quitarPersona(0, 'coordinador');
    this.quitarPersona(0, 'js');
    this.quitarPersona(0, 'jt');
    this.quitarPersona(0, 'recepcionista');
    this.quitarPersona(0, 'central');
    this.quitarPersona(0, 'monitorista');
    this.quitarPersona(0, 'rondinero');
    this.quitarPersona(0, 'guardiaSeguridad');
  }


  crearRecepcionista(): FormGroup {
    return this.fb.group({
      turnoRecepcionista: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoRecepcionista: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoRecepcionista: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialRecepcionista: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }
  // contact formgroup
  crearCoordinador(): FormGroup {
    return this.fb.group({
      turnoCoordinador: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoCoordinador: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoCoordinador: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialCoordinador: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearJS(): FormGroup {
    return this.fb.group({
      turnoJS: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoJS: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoJS: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialJS: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearJT(): FormGroup {
    return this.fb.group({
      turnoJT: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoJT: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoJT: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialJT: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearCentral(): FormGroup {
    return this.fb.group({
      turnoCentral: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoCentral: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoCentral: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialCentral: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearMonitorista(): FormGroup {
    return this.fb.group({
      turnoMonitorista: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoMonitorista: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoMonitorista: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialMonitorista: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearRondinero(): FormGroup {
    return this.fb.group({
      turnoRondinero: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoRondinero: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoRondinero: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialRondinero: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }

  crearGuardiaSeguridad(): FormGroup {
    return this.fb.group({
      turnoGuardiaSeguridad: ['', Validators.compose([Validators.required])], // i.e Email, Phone
      sueldoGuardiaSeguridad: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      costoGuardiaSeguridad: ['', Validators.compose([Validators.required])], // i.e. Home, Office
      razonSocialGuardiaSeguridad: ['', Validators.compose([Validators.required])] // i.e. Home, Office
    });
  }


  // add a contact form group
  AgregarPersona(puesto: string) {
     if (puesto === 'coordinador') {
        this.contactList.push(this.crearCoordinador());
       } else {
               if (puesto === 'js') {
                   this.contactJS.push(this.crearJS());

                  } else {
                          if (puesto === 'jt') {
                            this.contactJT.push(this.crearJT());

                          } else {
                                 if (puesto === 'recepcionista') {
                                    this.contactRecepcionista.push(this.crearRecepcionista());
                                  } else {
                                        if (puesto === 'central') {
                                           this.contactCentral.push(this.crearCentral());
                                            } else {
                                                   if (puesto === 'monitorista') {
                                                      this.contactMonitorista.push(this.crearMonitorista());
                                                      } else {
                                                            if (puesto === 'rondinero') {
                                                                this.contactRondinero.push(this.crearRondinero());
                                                                } else {
                                                                       if (puesto === 'guardiaSeguridad') {
                                                                          this.contactGuardiaSeguridad.push(this.crearGuardiaSeguridad());
                                                                          } else {

                                                                                 }
                                                                       }

                                                             }

                                                   }
                                          }
                                 }
                          }
               }
  }


 // remove contact from group
 quitarPersona(index, puesto: string) {
  // this.contactList = this.form.get('contacts') as FormArray;
  if (puesto === 'coordinador') {
    this.contactList.removeAt(index);
  } else {
       if (puesto === 'js') {
       this.contactJS.removeAt(index);

       } else {
         if (puesto === 'jt') {
           this.contactJT.removeAt(index);

         } else {
          if (puesto === 'recepcionista') {
            this.contactRecepcionista.removeAt(index);

          } else {
            if (puesto === 'central') {
              this.contactCentral.removeAt(index);

            } else {
              if (puesto === 'monitorista') {
                this.contactMonitorista.removeAt(index);

              } else {
                if (puesto === 'rondinero') {
                  this.contactRondinero.removeAt(index);

                 } else {
                  if (puesto === 'guardiaSeguridad') {
                    this.contactGuardiaSeguridad.removeAt(index);

                   } else {
                          }
                        }
              }
            }

          }

         }

       }
  }
}

  // get the formgroup under contacts form array
  getContactsFormGroup(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactList.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupJS(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactJS.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupJT(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactJT.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupRecepcionista(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactRecepcionista.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupCentral(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactCentral.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupMonitorista(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactMonitorista.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupRondinero(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactRondinero.controls[index] as FormGroup;
    return formGroup;
  }

  getContactsFormGroupGuardiaSeguridad(index): FormGroup {
    // this.contactList = this.form.get('contacts') as FormArray;
    const formGroup = this.contactFormGroupGuardiaSeguridad.controls[index] as FormGroup;
    return formGroup;
  }

  // method triggered when form is submitted
  submit() {
    if (!this.form.valid) {
      Swal.fire({
        title: 'Falta info por llenar',
        text: 'completa la información',
        type: 'warning'
      });
      return ;
    } else {
         console.log(this.form.value);
         this.guardar(this.form.value);
    }
  }

  guardar(form: FormGroup) {

    if (form.invalid) {
      Swal.fire({
        title: 'Falta info por llena',
        text: 'completa la información',
        type: 'warning'
      });
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    /*if (this.producto.claveProducto) {
      // peticion = this.productoService.actualizarProducto(this.producto, this.token);
    } else {
      peticion = this.productoService.crearProducto(this.producto, this.token);
    }*/
    peticion = this.plantillaService.crearServicio(form, this.token);

    peticion.subscribe( resp => {
      if(resp.response === "ok") {
        Swal.fire({
         title: 'correcto',
         text: 'Se Registro correctamente',
         type: 'success'
        });
        this.form.reset();
        this.router.navigate(['/plantillas', this.id ]);
      } else{
         Swal.fire({
           title: 'Error',
           text: 'Intentelo de nuevo',
           type: 'warning'
          });
        }
    });
  }
}

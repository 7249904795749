import { Component, OnInit } from '@angular/core';
import { VacanteModel } from '../../models/vacante.model';
import { ClienteModel } from '../../models/cliente.model';
import { ClientesService } from '../../services/clientes/clientes.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vacante',
  templateUrl: './vacante.component.html',
  styleUrls: ['./vacante.component.css']
})
export class VacanteComponent implements OnInit {

  vacante: VacanteModel = new VacanteModel();
  turno: VacanteModel[] = [];
  accionUpdate = false;
  token: string;
  clientes: ClienteModel[] = [];
  constructor(private clienteService: ClientesService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.token = localStorage.getItem('token');
    const id = this.route.snapshot.paramMap.get('id');
    this.cargarTurnos();

    if ( id !== 'nuevo' ) {
       this.accionUpdate = true;
       this.clienteService.getVacanteID(this.token, id )
       .subscribe( resp => {
         this.vacante = resp.response['0'];
        });
    } else {
     this.clienteService.getVacantes(this.token, localStorage.getItem('cveEmp'), 'cliente')
     .subscribe( resp => {
       this.accionUpdate = false;
     });
     this.cargarClientes();
    }
   }

   guardar(form: NgForm) {

    if (form.invalid) {
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    if (this.vacante.claveVacante) {
      peticion = this.clienteService.actualizarVacante(this.vacante, this.token);
    } else {
      peticion = this.clienteService.crearVacante(this.vacante, this.token);
    }
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.vacante.nombreEmpresa,
        text: 'Se Registro correctamente',
        type: 'success'
      });
    });
  }

  cargarClientes() {
    this.clienteService.getClientes(localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {
      if (resp.response['0'].claveCliente != null) {
         this.clientes = resp.response;
      }

    });
  }

  cargarTurnos() {
    this.clienteService.getTurno()
    .subscribe( (resp: any) => {
      if (resp.response['0'].claveTurno != null) {
         this.turno = resp.response;
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// rutas
import { ALMACEN_ROUTES } from './almacen.routes';
import { NgxPaginationModule } from 'ngx-pagination';

// components
import { AlmacenComponent } from './pages/almacen/almacen.component';
import { ProductoComponent } from './pages/producto/producto.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { UniformeComponent } from './pages/uniforme/uniforme.component';
import { AsignacionUniformeComponent } from './pages/asignacion-uniforme/asignacion-uniforme.component';
import { AsignacionesComponent } from './pages/asignaciones/asignaciones.component';
import { UniformesComponent } from './pages/uniformes/uniformes.component';
import { EntradaUniformeComponent } from './pages/entrada-uniforme/entrada-uniforme.component';
import { AsignacionesUniformesComponent } from './pages/asignaciones-uniformes/asignaciones-uniformes.component';
import { AsignacionServiciosComponent } from './pages/asignacion-servicios/asignacion-servicios.component';
import { UniformeAsignadoComponent } from './pages/uniforme-asignado/uniforme-asignado.component';

// pipes
import { FolioPapeletaPipe } from './pipes/folio-papeleta.pipe';
import { AsignacionEquipoComponent } from './pages/asignacion-equipo/asignacion-equipo.component';


@NgModule({
  declarations: [
    FolioPapeletaPipe,
    AlmacenComponent,
    ProductoComponent,
    ProductosComponent,
    AsignacionesComponent,
    UniformeComponent,
    AsignacionUniformeComponent,
    UniformesComponent,
    EntradaUniformeComponent,
    AsignacionesUniformesComponent,
    AsignacionServiciosComponent,
    UniformeAsignadoComponent,
    AsignacionEquipoComponent,
    ],
  exports: [
],
  imports: [
    ALMACEN_ROUTES,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,    
  ]
})

export class AlmacenModule { }

<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">

                <li class="sidebar-item user-profile">
                    <a class="has-arrow waves-effect waves-dark" aria-expanded="false"><img [src]="imagen" alt="user" />
                        <span class="hide-menu"> {{ departamento }} </span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLinkActive="Active" routerLink="/accout-settings">Configuración</a></li>
                        <li><a routerLinkActive="Active" (click)="salir()" class="pointer">Cerrar Sesión</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li>
                <li *ngFor="let menu of menuSis">
                    <a class="has-arrow waves-effect waves-dark" aria-expanded="false"><i [class]="menu.icono"></i><span class="hide-menu">{{ menu.titulo }} <span class="label label-rouded label-themecolor pull-right">{{ menu.submenu.length }}</span></span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let submenu of menu.submenu">
                            <a routerLinkActive="active" [routerLink]="[submenu.url] ">{{ submenu.titulo }} </a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</aside>
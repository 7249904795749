<app-head-estadistico
[texto] = texto
[icono] = icon
[numeros] = numeros
></app-head-estadistico>

<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
                <input name="filtroNombre" type="text" class="form-control" placeholder="Buscar cliente..." [(ngModel)]="filtroNombre">
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-right">
                            <button [routerLink]="['/cliente/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-primary">
                                                       <i class="fa fa-plus"> Agregar Cliente</i>
                            </button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-dark">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Logo</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Cliente</p>
                                </th>

                                <th>
                                    <p class="font-weight-bold text-white">Status</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Cliente</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Acciones</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of cliente | filterName: filtroNombre | paginate: config; ">
                                <td><img [src]='f.urlphoto' width='80px' height='70px' /></td>
                                <td>{{ f.nombreEmpresa | uppercase }}</td>
                                <td>
                                    <p *ngIf="f.statuscliente=='activo'" class="badge badge-success"> activo</p>
                                    <p *ngIf="f.statuscliente=='baja'" class="badge badge-danger"> baja</p>
                                    <p *ngIf="f.statuscliente=='verificado'" class="badge badge-primary"> Verificado</p>
                                </td>
                                <td>
                                    <button class="btn btn-info mr-1 btn-sm" [routerLink]="['/cliente',f.claveCliente]">                        
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                </td>
                                <td>
                                    <button class="btn btn-primary btn-sm m-b-10" (click)="updateReport( f.claveCliente)"> 
                                        <i class="fa fa-file-pdf-o"></i> Actualizar PDF
                                      </button>
                                    <button class="btn btn-danger btn-sm m-b-10" (click)="download( f.claveCliente)"> 
                                    <i class="fa fa-file-pdf-o"></i> Descargar PDF
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousp="Anterior" nextp="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsuarioService } from '../services/usuario/usuario.service';


@Injectable({
  providedIn: 'root'
})
export class ComercialGuard implements CanActivate {
  constructor(public usuarioService: UsuarioService, public router: Router) {

  }

  canActivate( ) {
    if ( localStorage.getItem('departamento') === 'Direccion' || localStorage.getItem('departamento') === 'Sistemas' 
    || localStorage.getItem('departamento') === 'Comercial') {
      return true;
    } else {
        this.router.navigate(['/login']);
        this.usuarioService.logout();
        return false;
      }
  }
}

import { Component, OnInit } from '@angular/core';
import { ClienteModel } from '../../models/cliente.model';
import { ClientesService } from '../../services/clientes/clientes.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {

  cliente: ClienteModel = new ClienteModel();
  accionUpdate = false;
  token: string;
  imagenSubir: File;
  imagenTemp: string | ArrayBuffer;
  constructor(private clienteService: ClientesService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.token = localStorage.getItem('token');
    const id = this.route.snapshot.paramMap.get('id');
    if ( id !== 'nuevo' ) {
       this.accionUpdate = true;
       this.clienteService.getClientID(id)
       .subscribe( resp => {
         this.cliente = resp.response['0'];
        });
    } else {
     this.clienteService.getClientes(localStorage.getItem('cveEmp'), 'cliente')
     .subscribe( resp => {
       this.accionUpdate = false;
     });
    }
   }

   guardar(form: NgForm) {

    if (form.invalid) {
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    if (this.cliente.claveCliente) {
      peticion = this.clienteService.actualizarCliente(this.cliente, this.token);
    } else {
      peticion = this.clienteService.crearCliente(this.cliente, this.token);
    }
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.cliente.nombreEmpresa,
        text: 'Se Registro correctamente',
        type: 'success'
      });
    });
  }

  seleccionImage( archivo: File ) {

    if ( !archivo ) {
      this.imagenSubir = null;
      return;
    }

    if ( archivo.type.indexOf('image') < 0 ) {
      Swal.fire('Sólo imágenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

    this.imagenSubir = archivo;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL( archivo );

    reader.onloadend = () => this.imagenTemp = reader.result;

  }

  cambiarImagen(id: string) {
    // llamar services
    this.clienteService.cambiarImagen(this.imagenSubir, id, this.token).subscribe((res) => {
      console.log('Respuesta:', res['ok']);
      if (res['ok']) {
      Swal.fire({
        title: this.cliente.nombreEmpresa,
        text: 'Se actualizo correctamente el logo',
        type: 'success'
      });
      }
     });
  }

}

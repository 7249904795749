import { Component, OnInit } from '@angular/core';
import { AlmacenService } from '../../almacen/services/almacen/almacen.service';

@Component({
  selector: 'app-graficas1',
  templateUrl: './graficas1.component.html',
  styles: []
})
export class Graficas1Component implements OnInit {
  token: string;


  graficos: any = {
    grafico1: {
      labels: ['Pantalón', 'Camisa', 'Chamarra', 'Gorra', 'Chaleco', 'Zapatos', 'Corbata', 'Lentes', 'Tapones'],
      data:  [0, 0, 0, 0, 0, 0, 0, 0, 0],
      type: 'doughnut',
      backgroundColor: [
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 0, 0.1)',
        'rgba(0, 0, 10, 0.1)',
        'rgba(0, 5, 0, 0.1)',
    ],
      leyenda: 'Salidas del día'
    }
  };
  graficos2: any = {
    grafico2: {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      data: [
        { data: [55000, 50000, 60000, 40000, 56000, 53000, 40000, 33000, 44000, 40000, 44000, 36000], label: 'Ingreso' },
        { data: [30000, 30000, 30000, 40000, 24000, 27000, 40000, 44000, 36000, 40000, 33000, 44000], label: 'Egreso' }
      ],
      type: 'bar',
      leyenda: 'Entradas y Salidas'
    }
  };
  salidasTotales = 0;
  entradasTotales = 0;
  movimientosTotales = 0;
  constructor(private almacenService: AlmacenService) {
  }
  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.cargarStockGrafico();

  }

  cargarStockGrafico() {
    const cantidadGrafico = [];
    const tipoUniformeGrafico = [];
    let salidas = 0;

    this.almacenService.getUniformeGrafico(this.token)
    .subscribe( (resp: any) => {
      if (resp.response['0'].cantidadGrafico != null) {
       // this.uniforme = resp.response;
        resp.response.forEach(function callback(currentValue, index, array) {
          //   console.log(currentValue, index);
             cantidadGrafico.push(currentValue.cantidadGrafico);
             tipoUniformeGrafico.push(currentValue.tipoUniformeGrafico);
             salidas += currentValue.cantidadGrafico;

        });
        this.salidasTotales = salidas;

        this.movimientosTotales = this.movimientosTotales + this.salidasTotales;
        // console.log(salidas);
        this.graficos = {
            grafico1: {
              labels: tipoUniformeGrafico,
              data:  cantidadGrafico,
              type: 'doughnut',
              leyenda: 'Salidas de Uniformes / Día'
            }
          };
      } else {
        console.log('sin datos');

      }
    });
  }

}

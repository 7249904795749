export class Usuario {
       email: string;
       password: string;
       nombre?: string;
       urlphoto?: string;
       // tslint:disable-next-line: variable-name
       _id?: string;
       cveDep: string;
       role: string;
}






import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grafico-bar',
  templateUrl: './grafico-bar.component.html',
  styles: []
})
export class GraficoBarComponent implements OnInit {

  // tslint:disable-next-line: no-input-rename
  @Input('chartLabels') barChartLabels: string[] = [];
  // tslint:disable-next-line: no-input-rename
  @Input('chartData') barChartData: any[] = [];
  // tslint:disable-next-line: no-input-rename
  @Input('chartType') barChartType = '';
   barChartLegend = true;

  constructor() { }

  ngOnInit() {
  }

}

<app-head-estadistico
[texto] = texto
[numeros] = numeros
[icono] = icon
></app-head-estadistico>
<div class="row animated fadeIn">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="text-right">
                        <button class="btn btn-info m-b-15 btn-ms" [routerLink]="['/plantilla',id]">                        
                        <i class="fa fa-plus"></i> Agregar vacante
                      </button>
                </div>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-dark">
                            <tr>
                                <th>
                                    <p class="text-white">Servicio</p>
                                </th>
                                <th>
                                    <p class="text-white">Zona</p>
                                </th>
                                <th>
                                    <p class="text-white">Puesto</p>
                                </th>
                                <th>
                                    <p class="text-white">Turno</p>
                                </th>
                                <th>
                                    <p class="text-white">Sueldo</p>
                                </th>
                                <th>
                                    <p class="text-white">Costo</p>
                                </th>
                                <th>
                                    <p class="text-white">Razón Social</p>
                                </th>
                                <th>
                                    <p class="text-white">Acción</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of vacante | paginate: config; let i = index">
                                <td>{{ f.servicio }}</td>
                                <td>{{ f.zona | titlecase }}</td>
                                <td>{{ f.puesto | titlecase}}</td>
                                <td>{{ f.turno | lowercase }}</td>
                                <td>{{ f.sueldo | currency:'MXN':'symbol-narrow'}} </td>
                                <td>{{ f.costoUnitario | currency:'MXN':'symbol-narrow' }} </td>
                                <td>{{ f.RazonSocial | titlecase }} </td>
                                <td>
                                    <button class="btn btn-danger btn-sm" (click)="borrarVacante( f.claveVacante ,i)">
                                   <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
export class VacanteModel {
  claveCliente: string;
  claveVacante: string;
  claveTurno: string;
  nombreEmpresa: string;
  servicio: string;
  puesto: string;
  TipoDepartamento: string;
  fechaInicio: Date;
  fechaLimite: Date;
  status: string;
  turno: string;
  zona: string;
  tipoVacante: string;
  fuc: string;
  diasTranscurridos: string;
  sueldo: string;
  RazonSocial: string;
  costoUnitario: string;
}

<app-head-estadistico
[texto] = texto
[icono] = icon
[numeros] = numeros
></app-head-estadistico>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card ">
            <div class="card-body">
                <div class="row">
                    <h3 class="card-title col-sm-3" align="center">
                        Filtrar equipo por: {{ checkFactura }}
                    </h3>
                    <div class="form-check col-sm-2">
                        <input #todos class="form-check-input" type="radio" [(ngModel)]="checkFactura" (click)="buscarProducto(todos.value)" id="p_todas" value="" checked>
                        <label class="form-check-label" for="p_todas">
                              Todos
                            </label>
                    </div>
                    <div class="form-check col-sm-2">
                        <input #Asignado class="form-check-input" type="radio" [(ngModel)]="checkFactura" (click)="buscarProducto(Asignado.value)" id="p_Asignado" value="Asignado">
                        <label class="form-check-label" for="p_Asignado">
                              Equipo Asignado
                            </label>
                    </div>
                    <div class="form-check col-sm-2">
                        <input #Disponible class="form-check-input" type="radio" [(ngModel)]="checkFactura" (click)="buscarProducto(Disponible.value)" id="p_Disponible" value="Disponible">
                        <label class="form-check-label" for="p_Disponible">
                            Equipo Disponible
                            </label>
                    </div>

                    <div class="col-sm-3">
                        <div class="text-right">
                            <button [routerLink]="['/producto/nuevo']" type="button" class="btn waves-effect waves-light btn-rounded btn-themecolor">
                                                       <i class="fa fa-plus"> Agregar Equipo</i>
                            </button>
                        </div>
                    </div>

                </div>
                <hr>

                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Identificador</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Modelo</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Procesador</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Ram</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Disco Duro</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Sistema Operativo</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">serial / observación</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo de Equipo</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Estatus</p>
                                </th>
                                <p class="font-weight-bold text-white">Acción</p>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let p of producto | paginate: config; let i = index">
                                <td>{{ p.identificador | uppercase }}</td>
                                <td>{{ p.modelo | lowercase}}</td>
                                <td>{{ p.procesador }}</td>
                                <td>{{ p.ram | lowercase }} </td>
                                <td>{{ p.discoDuro | lowercase }} </td>
                                <td>{{ p.sistemaOperativo | lowercase }} </td>
                                <td>{{ p.observacion | lowercase }} </td>
                                <td>{{ p.tipoProducto | lowercase }} </td>
                                <td>
                                    <label *ngIf="p.status!='Asignado'" class="badge badge-success"> Disponible</label>
                                    <label *ngIf="p.status=='Asignado'" class="badge badge-danger"> Asignado</label>
                                </td>
                                <td>
                                    <button class="btn btn-info mr-1" [routerLink]="['/producto',p.claveProducto]">                        
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                    <!--button class="btn btn-danger" (click)="borrarCliente( f ,i)">
                                   <i class="fa fa-trash"></i>
                                  </button-->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="text-right">
                    <button [routerLink]="['/contrataciones']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger">
                                               <i class="fa fa-arrow-left"> Regresar</i>
                    </button>

                </div>
                <h3><strong>Uniformes entregados: </strong>{{ nombre | titlecase }}</h3>
                <h3 class="card-title">Total (<small>{{ uniforme.length }}</small>)</h3>
                <div class="table-responsive">
                    <table *ngIf="!cargando" class="table table-striped animated fadeIn faster" width="100%" cellspacing="0">
                        <thead class="bg-theme">
                            <tr>
                                <th>
                                    <p class="font-weight-bold text-white">Tipo de Uniforme</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Talla</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Descripción</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Cantidad</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Estado</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Fecha de entrega</p>
                                </th>
                                <th>
                                    <p class="font-weight-bold text-white">Acción</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let f of uniforme | paginate: config; let i = index">
                                <td>{{ f.tipoUniforme }}</td>
                                <td>{{ f.talla }}</td>
                                <td>{{ f.descripcion | titlecase}}</td>
                                <td>{{ f.cantidad }}</td>
                                <td>{{ f.estado }} </td>
                                <td>{{ f.fechaEntrega | date }} </td>
                                <td>
                                    <button class="btn btn-danger" (click)="borrarVacante( f.claveOperAsignacionUniforme ,i)">
                                   <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div align="right">
                        <pagination-controls (pageChange)="pageChanged($event)" autoHide="true" responsivo="true" directionLinks="true" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
                    </div>
                </div>
                <div *ngIf="cargando && result == false" class="alert alert-info text-center mt-3 animated fadeIn faster">
                    <h4 clas="alert-heading">Cargando</h4>
                    <p>
                        <i class="fa fa-sync-alt fa-spin fa 2x"></i>
                    </p>
                    <p class="mb-0">
                        Espere por favor
                    </p>
                </div>
                <div *ngIf="!cargando && result == true" class="alert alert-warning text-center mt-3">
                    <h4 clas="alert-heading">No Se encontraron registros</h4>
                    <p>
                        <i class="fa fa-exclamation fa 2x"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { VacanteModel } from 'src/app/models/vacante.model';
import Swal from 'sweetalert2';
import { PlantillaService } from '../../services/plantilla/plantilla.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.css']
})
export class PlantillasComponent implements OnInit {

  vacante: VacanteModel[] = [];
  cargando = false;
  result = false;
  token: string;
  config: any;
  id: any;

  texto = ['Estado de fuerza total', 'Costo total', 'Servicios'];
  numeros: any[];
  icon = ['fas fa-user', 'fas fa-usd', 'fas fa-hashtag'];
  constructor(public plantillaService: PlantillaService, private route: ActivatedRoute) {

    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0
    };
   }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.numeros = [0, 0, 0];
    this.id = this.route.snapshot.paramMap.get('id');
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarPlantilla(this.id);
  }

  cargarPlantilla(id: string) {
    this.plantillaService.getPlantilla(this.token, id)
    .subscribe( (resp: any) => {
      if (resp.response['0'] != null) {
         this.vacante = resp.response;
         this.cargarPlantillaEstadistico(this.id, this.vacante.length);
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  borrarVacante( vacante: string, i: number) {
    Swal.fire({
      title: '¿Está Seguro?',
      text: `Esta Seguro que desea borrar la vacante?`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {
      if (resp.value) {
        this.plantillaService.borrarVacante(vacante, this.token)
        .subscribe( () => {
          this.vacante.splice( i, 1 );
          this.cargarPlantilla(this.id);
          this.cargarPlantillaEstadistico(this.id, this.vacante.length);
        });
      }
    });
  }

  cargarPlantillaEstadistico(id: string, vacantes: number) {
    this.plantillaService.getPlantillaEstadistico(this.token, id)
    .subscribe( (resp: any) => {
      if (resp.response['0'] != null) {
         this.numeros = [ vacantes, resp.response['0'].costo , resp.response['0'].servicios];

      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }
}

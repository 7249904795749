<div class="animated fadeIn faster">
    <hr>
    <div class="row text-right">
        <div class="col">
            <button [routerLink]="['/vacantes']" class="btn btn-danger">
                               <i class="fa fa-arrow-circle-left"></i>
                                Regresar
                            </button>
        </div>
    </div>
    <div class="row animated fadeIn faster">
        <div class="col">
            <form (ngSubmit)="guardar( f )" #f="ngForm">
                <div class="form-group">
                    <label> Id vacante</label>
                    <input type="text" class="form-control" name="claveCliente" [(ngModel)]="vacante.claveVacante" placeholder="Id cliente" disabled="disabled">
                    <small class="form-text text-muted">Este campo es autogenerado</small>
                </div>

                <div *ngIf="!accionUpdate" class="form-group">
                    <label>SELECCIONE EL CLIENTE</label>
                    <select class="form-control" name="nombreEmpresa" [(ngModel)]="vacante.nombreEmpresa" required>
                        <option selected disabled>Seleccione el cliente...</option>
                        <option *ngFor="let cliente of clientes | filtroCliente" [value]="cliente.claveCliente">{{ cliente.nombreEmpresa | uppercase }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label> Servicio</label>
                    <input type="text" class="form-control" name="servicio" [(ngModel)]="vacante.servicio" required placeholder="Servicio">
                </div>
                <div class="form-group">
                    <label> Puesto</label>
                    <input type="email" class="form-control" name="puesto" [(ngModel)]="vacante.puesto" required placeholder="Puesto">
                </div>
                <div *ngIf="accionUpdate" class="form-group">
                    <label> Fecha limite</label>
                    <input type="text" class="form-control" name="fechaLimite" [(ngModel)]="vacante.fechaLimite" required placeholder="fecha limite">
                </div>
                <div *ngIf="!accionUpdate" class="form-group">
                    <label> Fecha limite</label>
                    <input type="date" class="form-control" name="fechaLimite" [(ngModel)]="vacante.fechaLimite" required placeholder="fecha limite">
                </div>
                <div class="form-group">
                    <label>Turno</label>
                    <select class="form-control" name="turno" [(ngModel)]="vacante.turno" required>
                    <option *ngFor="let turn of turno; let i = index" [value]="turn.claveTurno">{{ turn.turno }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Zona</label>
                    <select class="form-control" name="zona" [(ngModel)]="vacante.zona" required>
                                       <option value="Queretaro">Queretaro</option>
                                       <option value="Parque">Parque</option>
                                       <option value="SLP">SLP</option>
                    </select>
                </div>
                <hr>
                <div class="form-group text-center">
                    <button type="submit" class="btn btn-primary w-25"><i class="fa fa-save"></i> Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>
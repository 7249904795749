<div class="row">
    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Temas </h4>
                <hr>
                <div class="r-panel-body">
                    <ul id="themecolors" class="m-t-20">
                        <li><b>Con el sidebar claro</b></li>
                        <li><a #link1 (click)="cambiarColor('default', link1)" data-theme="default" class="selector default-theme">1</a></li>
                        <li><a #link2 (click)="cambiarColor('green', link2)" data-theme="green" class="selector green-theme">2</a></li>
                        <li><a #link3 (click)="cambiarColor('red', link3)" data-theme="red" class="selector red-theme">3</a></li>
                        <li><a #link4 (click)="cambiarColor('blue', link4)" data-theme="blue" class="selector blue-theme">4</a></li>
                        <li><a #link5 (click)="cambiarColor('purple', link5)" data-theme="purple" class="selector purple-theme">5</a></li>
                        <li><a #link6 (click)="cambiarColor('megna', link6)" data-theme="megna" class="selector megna-theme">6</a></li>

                        <li class="d-block m-t-30"><b>Con el sidebar oscuro</b></li>
                        <li><a #link7 (click)="cambiarColor('default-dark', link7)" data-theme="default-dark" class="selector default-dark-theme">7</a></li>
                        <li><a #link8 (click)="cambiarColor('green-dark', link8)" data-theme="green-dark" class="selector green-dark-theme">8</a></li>
                        <li><a #link9 (click)="cambiarColor('red-dark', link9)" data-theme="red-dark" class="selector red-dark-theme">9</a></li>
                        <li><a #link10 (click)="cambiarColor('blue-dark', link10)" data-theme="blue-dark" class="selector blue-dark-theme">10</a></li>
                        <li><a #link11 (click)="cambiarColor('purple-dark', link11)" data-theme="purple-dark" class="selector purple-dark-theme">11</a></li>
                        <li><a #link12 (click)="cambiarColor('megna-dark', link12)" data-theme="megna-dark" class="selector megna-dark-theme">12</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { EmpleadoModel } from '../../models/empleado.model';
import { ServicioModel } from '../../models/servicio.model';
import { ClientesService } from '../../services/clientes/clientes.service';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ContratacionService } from '../../services/contratacion/contratacion.service';
import { ClienteModel } from 'src/app/models/cliente.model';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-contratacion',
  templateUrl: './contratacion.component.html',
  styleUrls: ['./contratacion.component.css']
})
export class ContratacionComponent implements OnInit {
  empleado: EmpleadoModel = new EmpleadoModel();
  servicio: ServicioModel[] = [];
  token: string;
  cliente: ClienteModel = new ClienteModel();
  accionUpdate = false;
  constructor(private  clienteService: ClientesService, private contratacionService: ContratacionService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.cargarClientes();
    const id = this.route.snapshot.paramMap.get('id');

    if ( id !== 'nuevo' ) {
       this.accionUpdate = true;
       this.contratacionService.getEmpleadoID(this.token, id )
       .subscribe( resp => {
         this.empleado = resp.response['0']['0'];
         console.log(this.empleado);
        });
    } else {
       this.accionUpdate = false;
    }
  }

  cargarClientes() {
    this.clienteService.getVacantes(this.token, localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {
    //  console.log(resp);
    /*  if (resp.response['0']['0'].claveVacante != null) {
         this.servicio = resp.response['0'];
      }*/

    });
  }

   guardar(form: NgForm) {

    if (form.invalid) {
      return ;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    });
    Swal.showLoading();
    let peticion: Observable<any>;
    if (this.cliente.claveCliente) {
    //  peticion = this.clienteService.actualizarCliente(this.cliente, this.token);
    } else {
     // peticion = this.clienteService.crearCliente(this.cliente, this.token);
    }
    peticion.subscribe( resp => {
      Swal.fire({
        title: this.cliente.nombreEmpresa,
        text: 'Se Registro correctamente',
        type: 'success'
      });
    });
  }
}

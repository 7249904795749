import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  usuario: string;
  departamento: string;
  imagen: string;
  email: string;

  constructor( public sidebar: SidebarService, private authService: UsuarioService, public router: Router ) { }

  ngOnInit() {
    this.usuarioData();
  }

  public usuarioData() {
    this.usuario = localStorage.getItem('usuario');
    this.departamento = localStorage.getItem('departamento');
    this.imagen = localStorage.getItem('urlphoto');
    this.email = localStorage.getItem('email');
}

  salir() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PAGES_ROUTES } from './pages.routes';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

// ng2-charts
import { ChartsModule } from 'ng2-charts';
import { Graficas1Component } from './graficas1/graficas1.component';
import { GraficoDonaComponent } from '../components/grafico-dona/grafico-dona.component';
import { GraficoBarComponent } from '../components/grafico-bar/grafico-bar.component';

// pages
import { PagesComponent } from './pages.component';
import { DhasboardComponent } from './dhasboard/dhasboard.component';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClienteComponent } from './cliente/cliente.component';
import { ContratacionesComponent } from './contrataciones/contrataciones.component';
import { BajasComponent } from '../pages/bajas/bajas.component';

import { ContratacionComponent } from './contratacion/contratacion.component';
import { ServicioClienteComponent } from './servicio-cliente/servicio-cliente.component';
import { VacantesComponent } from './vacantes/vacantes.component';
import { VacanteComponent } from './vacante/vacante.component';

import { PlantillaComponent } from './plantilla/plantilla.component';
import { PlantillasComponent } from './plantillas/plantillas.component';
import { PipesModule } from '../pipes/pipes.module';


// Pipe Module
import { FiltroClientePipe } from '../pipes/filtro-cliente.pipe';
import { FilterNamePipe } from '../pipes/filter-name.pipe';
import { FilterNameEmpleadoPipe } from '../pipes/filter-name-empleado.pipe';
import { AdministrativosComponent } from './administrativos/administrativos.component';
import { ReportesemanalComponent } from './reportesemanal/reportesemanal.component';

@NgModule({
    declarations: [
        FiltroClientePipe,
        FilterNamePipe,
        FilterNameEmpleadoPipe,
        PagesComponent,
        DhasboardComponent,
        Graficas1Component,
        AccoutSettingsComponent,
        GraficoDonaComponent,
        GraficoBarComponent,
        ClientesComponent,
        ClienteComponent,
        ContratacionesComponent,
        ContratacionComponent,
        ServicioClienteComponent,
        VacantesComponent,
        VacanteComponent,
        PlantillaComponent,
        PlantillasComponent,
        BajasComponent,
        AdministrativosComponent,
        ReportesemanalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PAGES_ROUTES,
        BrowserModule,
        ChartsModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule
    ]
})
export class PagesModule { }

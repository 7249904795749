import { Component, OnInit } from '@angular/core';
import { EmpleadoModel } from 'src/app/models/empleado.model';
import { ContratacionService } from 'src/app/services/contratacion/contratacion.service';

@Component({
  selector: 'app-bajas',
  templateUrl: './bajas.component.html',
  styleUrls: ['./bajas.component.css']
})
export class BajasComponent implements OnInit {
  filtroNombre: any;
  empleado: EmpleadoModel[] = [];
  empleadoID: EmpleadoModel = new EmpleadoModel();
  emp: EmpleadoModel = new EmpleadoModel();
  cargando = false;
  result = false;
  token: string;
  config: any;
  temp = [];
  constructor(public contratacionService: ContratacionService) {
  }

   pageChanged( event ) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.filtroNombre = '';
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.token = localStorage.getItem('token');
    this.cargarEmpleados();
  }

  cargarEmpleados() {
    this.contratacionService.getEmpleadoBaja(this.token, localStorage.getItem('cveEmp'), 'cliente')
    .subscribe( (resp: any) => {

      if (resp.response['0'].claveEmpleado != null) {
         this.empleado = resp.response;
      } else {
        this.result = true;
      }
      this.cargando = false;
    });
  }

  detalleInfo(id: string) {
    if ( id !== 'nuevo' ) {
      this.contratacionService.getEmpleadoID(this.token, id )
      .subscribe( resp => {
        this.empleadoID = resp.response['0'];
        // console.log(this.empleadoID);
       });
   }
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsuarioService } from '../../services/usuario/usuario.service';
import Swal from 'sweetalert2';
import { Usuario } from '../../models/usuario.model';
declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: Usuario = new Usuario();
  recuerdame = true; // si le pongo false si respecta lo de recordar y no recordar pero quiero que siempre recuerde el usuario
  email: string;
  constructor(public router: Router, public usuarioService: UsuarioService) { }

  ngOnInit() {
    init_plugins();
    this.email = localStorage.getItem('email') || '';
    if (this.email.length > 1) {
      this.recuerdame = true;
    }
  }

  ingresar( forma: NgForm) {
    if ( forma.invalid ) {
       return;
       }
    this.usuario.email = forma.value.email;
    this.usuario.password = forma.value.password;
    Swal.fire({
       allowOutsideClick: false,
       type: 'info',
       text: 'Cargando...'
     });
    Swal.showLoading();
    this.usuarioService.login(this.usuario, forma.value.recuerdame)
    .subscribe(resp => {
      Swal.close();
         // tslint:disable-next-line: no-string-literal
      if (resp['ok'] !== true) {
           Swal.fire({
            allowOutsideClick: false,
            type: 'error',
            title: 'Error al autenticar',
            text: 'Usuario o contraseña Incorrecto'
          });
         }
         // tslint:disable-next-line: no-string-literal
      if (resp['ok'] === true) {
           this.usuario = resp.response;
           this.router.navigateByUrl('/dhasboard');
         }
     }, (err) => {
      Swal.fire({
        allowOutsideClick: false,
        type: 'error',
        title: 'Error al autenticar',
        text: 'Usuario o contraseña Incorrecto'
      });
    });
  }

}

<div class="col-12">
    <form [formGroup]="form" (submit)="submit()" #myform="ngForm">
        <div class="card">

            <div class="card-header">Registro de Entrada de Uniformes</div>
            <div class="card-body">
                <div class="text-right">
                    <button [routerLink]="['/almacen']" type="button" class="btn waves-effect waves-light btn-rounded btn-danger">
                                               <i class="fa fa-arrow-left"> Regresar</i>
                    </button>
                </div>
            </div>

            <div class="card-body border border-secondary col-md-12">
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('pantalon')"><i class="fa fa-plus-circle"></i><strong> pantalón (PT) </strong><span class="badge badge-light"><strong>{{pantalonFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('camisa')"><i class="fa fa-plus-circle"></i><strong> camisa (CM) </strong><span class="badge badge-light"><strong>{{camisaFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('chamarra')"><i class="fa fa-plus-circle"></i><strong> chamarra (CH) </strong><span class="badge badge-light"><strong>{{chamarraFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('gorra')"><i class="fa fa-plus-circle"></i><strong> gorra (G) </strong><span class="badge badge-light"><strong>{{gorraFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('chaleco')"><i class="fa fa-plus-circle"></i><strong> chaleco (CHL) </strong><span class="badge badge-light"><strong>{{chalecoFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('chaleco')"><i class="fa fa-plus-circle"></i><strong> chaleco (CHL) </strong><span class="badge badge-light"><strong>{{chalecoFormGroup.controls.length}}</strong></span></button>
                <button class="btn btn-secondary col-md-4" type="button" (click)="AgregarPersona('sudadera')"><i class="fa fa-plus-circle"></i><strong> Sudadera (S) </strong><span class="badge badge-light"><strong>{{sudaderaFormGroup.controls.length}}</strong></span></button>
            </div>
            <div *ngIf="pantalonFormGroup.length" class="card-body border border-secondary" formArrayName="pantalon">
                <p class="list-group-item active">Registro de pantalones</p>

                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let contact of pantalonFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de pantalon # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaPantalon" type="text">
                                            <option [value]="28">28</option>
                                            <option [value]="30">30</option>
                                            <option [value]="32">32</option>
                                            <option [value]="34">34</option>
                                            <option [value]="36">36</option>
                                            <option [value]="38">38</option>
                                            <option [value]="40">40</option>
                                            <option [value]="42">42</option>
                                            <option [value]="44">44</option>
                                            <option [value]="46">46</option>
                                            <option [value]="46">46</option>
                                            <option [value]="48">48</option>
                                            <option [value]="50">50</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['tallaPantalon'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de pantalon # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadPantalon" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['cantidadPantalon'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion pantalon # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionPantalon" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['descripcionPantalon'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Fecha de entrega # {{i + 1}}</label>
                                <div class=" col-sm-3 form-group">
                                    <input type="date" class="form-control" formControlName="fechaEntregaPantalon" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getPantalonFormGroup(i).controls['fechaEntregaPantalon'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'pantalon')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="camisaFormGroup.length" class="card-body border border-secondary" formArrayName="camisa">
                <p class="list-group-item active">Registro de camisas</p>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let camisa of camisaFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de camisa # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaCamisa" type="text">
                                            <option [value]="28">28</option>
                                            <option [value]="30">30</option>
                                            <option [value]="32">32</option>
                                            <option [value]="34">34</option>
                                            <option [value]="36">36</option>
                                            <option [value]="38">38</option>
                                            <option [value]="40">40</option>
                                            <option [value]="42">42</option>
                                            <option [value]="44">44</option>
                                            <option [value]="46">46</option>
                                            <option [value]="48">48</option>
                                            <option [value]="50">50</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['tallaCamisa'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de camisa # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadCamisa" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['cantidadCamisa'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion Camisa # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionCamisa" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['descripcionCamisa'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Color de camisa # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoCamisa" type="text">
                                            <option value="Blanca">Blanca</option>
                                            <option value="Gris">Gris</option>
                                        </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['estadoCamisa'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaCamisa" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getCamisaFormGroup(i).controls['fechaEntregaCamisa'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'camisa')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="chamarraFormGroup.length" class="card-body border border-secondary" formArrayName="chamarra">
                <p class="list-group-item active">Registro de chamarras</p>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let chamarra of chamarraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de chamarra # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaChamarra" type="text">
                                            <option [value]="28">28</option>
                                            <option [value]="30">30</option>
                                            <option [value]="32">32</option>
                                            <option [value]="34">34</option>
                                            <option [value]="36">36</option>
                                            <option [value]="38">38</option>
                                            <option [value]="40">40</option>
                                            <option [value]="42">42</option>
                                            <option [value]="44">44</option>
                                            <option [value]="46">46</option>
                                            <option [value]="48">48</option>
                                            <option [value]="50">50</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['tallaChamarra'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de chamarra # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadChamarra" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['cantidadChamarra'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion Chamarra # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionChamarra" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['descripcionChamarra'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Fecha de entrega</label>
                                <div class=" col-sm-3 form-group">
                                    <input type="date" class="form-control" formControlName="fechaEntregaChamarra" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChamarraFormGroup(i).controls['fechaEntregaChamarra'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'chamarra')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="gorraFormGroup.length" class="card-body border border-secondary" formArrayName="gorra">
                <p class="list-group-item active">Registro de Gorras</p>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let gorra of gorraFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de gorra # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaGorra" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['tallaGorra'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de gorras # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadGorra" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['cantidadGorra'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion gorra # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionGorra" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['descripcionGorra'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Fecha de entrega</label>
                                <div class=" col-sm-3 form-group">
                                    <input type="date" class="form-control" formControlName="fechaEntregaGorra" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getGorraFormGroup(i).controls['fechaEntregaGorra'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'gorra')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="chalecoFormGroup.length" class="card-body border border-secondary" formArrayName="chaleco">
                <p class="list-group-item active">Registro de Chalecos</p>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let chaleco of chalecoFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de chaleco # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaChaleco" type="text">
                                            <option value="unitalla">unitalla</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['tallaChaleco'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de chalecos # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadChaleco" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['cantidadChaleco'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion de chaleco # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionChaleco" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['descripcionChaleco'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Color del chaleco # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="estadoChaleco" type="text">
                                                <option value="Amarillo">Amarillo</option>
                                                <option value="Azul">Azul</option>
                                                <option value="Naranja">Naranja</option>
                                              </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['estadoChaleco'].hasError('required') && myform.submitted">
                                        el estado es requerido
                                </span>
                                </div>
                                <div class=" col-sm-6 form-group">
                                    <label class="control-label">Fecha de entrega</label>
                                    <input type="date" class="form-control" formControlName="fechaEntregaChaleco" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getChalecoFormGroup(i).controls['fechaEntregaChaleco'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'chaleco')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="zapatoFormGroup.length" class="card-body border border-secondary" formArrayName="zapato">
                <p class="list-group-item active">Registro de Zapatos</p>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let zapato of zapatoFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de zapato # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaZapato" type="text">
                                            <option [value]="23">23</option>
                                            <option [value]="24">24</option>
                                            <option [value]="25">25</option>
                                            <option [value]="26">26</option>
                                            <option [value]="27">27</option>
                                            <option [value]="28">28</option>
                                            <option [value]="29">29</option>
                                            <option [value]="30">30</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['tallaZapato'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de zapatos # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadZapato" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['cantidadZapato'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion de zapato # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionZapato" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['descripcionZapato'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Fecha de entrega</label>
                                <div class="col-sm-3 form-group">
                                    <input type="date" class="form-control" formControlName="fechaEntregaZapato" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getZapatoFormGroup(i).controls['fechaEntregaZapato'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'zapato')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="sudaderaFormGroup.length" class="card-body border border-secondary" formArrayName="sudadera">
                <p class="list-group-item active">Registro de Sudadera</p>
                <br>
                <div class="row">
                    <div class="col-12" *ngFor="let sudadera of sudaderaFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group col-md-12 row animated fadeIn">
                                <label class="col-sm-3 control-label m-b-5">Talla de sudadera # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><i class="ti-split-v"></i></div>
                                        <select class="form-control" formControlName="tallaSudadera" type="text">
                                            <option value='Chica'>Chica</option>
                                            <option value='Mediana'>Mediana</option>
                                            <option value='Grande'>Grande</option>
                                         </select>
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getSudaderaFormGroup(i).controls['tallaSudadera'].hasError('required') && myform.submitted">
                                        la talla es requerida
                                </span>
                                </div>
                                <label class="col-sm-3 control-label m-b-5">cantidad de Sudaderas # {{i + 1}}</label>
                                <div class="col-sm-3 m-b-5">
                                    <div class="input-group">
                                        <div class="input-group-addon"><span>#</span></div>
                                        <input class="form-control" formControlName="cantidadSudadera" type="number" min="0" value="0" step="1">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getSudaderaFormGroup(i).controls['cantidadSudadera'].hasError('required') && myform.submitted">
                                        la cantidad es requerido
                                </span>
                                </div>
                                <label class="col-sm-3 control-label">Descripcion de Sudadera # {{i + 1}}</label>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input class="form-control" formControlName="descripcionSudadera" type="text">
                                    </div>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getSudaderaFormGroup(i).controls['descripcionSudadera'].hasError('required') && myform.submitted">
                                        el costo es requerido
                                   </span>
                                </div>
                                <label class="col-sm-3 control-label">Fecha de entrega</label>
                                <div class="col-sm-3 form-group">
                                    <input type="date" class="form-control" formControlName="fechaEntregaSudadera" required/>
                                    <span class="cross-validation-error-message alert alert-danger" *ngIf="getSudaderaFormGroup(i).controls['fechaEntregaSudadera'].hasError('required') && myform.submitted">
                                        la fecha es requerida
                                </span>
                                </div>
                                <div class="col-sm-6" align="right">
                                    <button class="btn btn-danger m-t-10" type="button" (click)="quitarPersona(i,'Sudadera')"><i class="fa fa-minus"></i> Quitar # {{i+1}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body" *ngIf="pantalonFormGroup.length || camisaFormGroup.length || chamarraFormGroup.length  || gorraFormGroup.length || zapatoFormGroup.length || chalecoFormGroup.length">
                <div class="col-12">
                    <button class="btn btn-success mr-1" type="submit"> <i class="fa fa-check"></i>  Guardar Entrada </button>
                    <button [routerLink]="['/almacen']" type="button" class="btn btn-danger">Cancelar</button>
                </div>
            </div>
        </div>
    </form>
</div>